.pre-results-callout{
  border-radius: 10px;
  background-color: $purple;
  margin: 0 0 40px;
  padding: 12px 35px 15px;
  display: flex;
  align-items: center;

  @media (max-width: 1440px) {
      display: block;
  }

  &__content{
    flex: 2;
    margin-right: 20px;

    @media (max-width: $break) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__header, &__text{
    color: $black;
    font-family: $main-font;
  }

  &__header{
    color: $white;
    font-family: $fontSansSecondary;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.51px;
  }

  &__text{
    color: $white;
    font-family: $fontSansSecondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    margin: 0px;
  }

  &__trigger{
    margin-left: 20px;
    border: 1px solid #D5D5D5;
    border-radius: 5px;
    background-color: $white;
    padding: 16px 40px;
    color: $black;
    font-family: $main-font;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    @media (max-width: $break) {
      margin: 0;
    }

    &:hover{
      cursor: pointer;
    }
  }
}