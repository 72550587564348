.c-provider-card {
    background: #FFFFFF;
    border: 1px solid #BA9CC5;
    border-radius: 0px 80px;

    h3 {
        font-family: $fontSansSecondary;
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        color: #0072CE;
        margin: 0 0 7px;
    }

    p {
        font-family: $fontSansSecondary;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        margin: 0 0 5px;
    }

    .section-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }

    select.locations {
        font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #0072CE;
        border: 1px solid #666;
        width: 100%;
        padding: 6px 30px 6px 10px;
        margin-bottom: 20px;
    }

    &__appointments {
        padding: 18px 24px 40px;
    
        @media screen and (min-width: 64em) {
            display: flex;
            flex-direction: row;
            gap: 36px;
            padding: 18px 36px;
        }

        > * {
            flex: 1;
        }

        .c-provider-card__schedule {
            @media screen and (min-width: 64em) {
                flex: 0 1 55%;
            }
        }
    }

    &__load-appointments {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $fontSansSecondary;
        font-size: 1.25rem;
    }
    
    &__provider-details {
        background: rgba(71, 10, 104, 0.09);
        border-radius: 0px 80px 0px 0px;
        padding: 23px 24px 20px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        @media screen and (max-width: 63.99875em) {
            flex-direction: column;
        }
    }

    &__header {
        margin-bottom: 30px;
    }
    
    &__img {
        border-radius: 0px 30px;
        display: flex;
        height: 212px;
        object-fit: cover;
        width: 228px;
        overflow: hidden;
        img {
            object-fit: cover;
            object-position: top center;
            width: 100%;
        }
        &-no-image {
            display: flex;
            background: #BA9CC5;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }
    
    &__provider-ratings {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        margin: 0 0 5px;
        &__stars {
            line-height: 1;
        }
        &__percentage {
            font-family: $fontSansSecondary;
            font-weight: 700;
            font-size: 18px;
            line-height: 16px;
            text-decoration-line: underline;
            color: #0072CE;
        }
        &__count {
            font-family: $fontSansSecondary;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #333333;
        }
    }

    &__provider-features {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px 32px;
        @media screen and (min-width: 64em) {
            height: 75px;
        }
    }

    &__schedule {
        .btn-view-all {
            margin: 0 auto;

            svg {
                margin-right: 5px;
            }
        }
    }

    .item__specialties {
        display: block;
        font-family: $fontSansSecondary;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
        margin-bottom: 7px;
    }

    .item__affiliation {
        display: flex;
        position: relative;
        font-family: $fontSansSecondary;
        font-weight: 700;
        font-size: 14px;
        gap: 5px;
        line-height: 18px;
        color: #470A68;
        margin: 0 0 24px;

        .affiliation-tooltip {
            position: absolute;
            top: 100%;
            left: 0;
            background: #470A68;
            color: #FFF;
            border-radius: 34px;
            padding: 1rem 1.5rem;
            min-width: 290px;

            @media screen and (min-width: 64em) {
                min-width: 400px;
            }

            p {
                color: #FFF;
            }
        }
    }

    .item__new,
    .item__virtual,
    .item__idioms,
    .item__ages {
        align-items: center;
        color: #333333;
        display: flex;
        font-family: $fontSansSecondary;
        font-weight: 400;
        font-size: 14px;
        gap: 5px;
        line-height: 18px;
    }

    .item__idioms,
    .item__ages {
        span {
            &::after {
                content: ',';
                display: inline;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    .item__clinic {
        display: block;
        font-family: $fontSansSecondary;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #0072CE;
        margin: 0 0 28px;
    }
}

.schedule-calendar {
    &__container {
        display: flex;
        gap: 36px;
        margin: 16px 0;
        width: 463px;
        @media screen and (max-width: 63.99875em) {
            margin: 16px 0 0;
            width: auto;
        }
        
        &.slick-slider {

            @media screen and (max-width: 63.99875em) {
                margin-bottom: 40px;
                overflow: hidden;
                width: 100%;
            }

            .slick-list {
                margin: 0 auto;
                width: 465px;

                .slick-track {
                    display: flex;

                    &::before, 
                    &::after {
                        display: none;
                    }
                    .slick-slide {
                        width: 33%;
                        > div {
                            width: 84%;
                        }
                    }
                }
            }

            // Control buttons
            .slick-prev, 
            .slick-next {
                top: 10%;
                z-index: 9;

                &::before {
                    content: '';
                    color: transparent;
                    border-style: solid;
                    border-width: 2px 2px 0 0;
                    border-color: #0072CE;
                    width: 10px;
                    height: 10px;
                    display: block;
                }
                &.slick-arrow.slick-disabled {
                    cursor: auto;
                }        
            }
            .slick-prev {
                left: 0;

                @media screen and (max-width: 63.99875em) {
                    left: 3px;
                }

                &::before {
                    transform: rotate(-135deg);
                }
            } 
            .slick-next {
                right: 18px;

                &::before {
                    transform: rotate(45deg);
                }
            }
        }

        &.footer {
            @media screen and (max-width: 63.99875em) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 21px;
            }
            p {
                text-align: center;
            }
            a.link {
                display: flex;
                align-items: center;
                justify-content: center;

                color: #2F72C7;
                font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
                font-size: 14px;
                font-weight: 700;
                line-height: 18px;

                svg {
                    margin-right: 8px;
                }
            }
        }
    }
    &__day {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__header {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        font-family: $fontSansSecondary;
        flex-direction: column;
        line-height: 15px;
        margin-bottom: 10px;
    }
    &__date {
        font-weight: 700;
    }
    &__weekday {
        text-transform: uppercase;
    }
    &__hours {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &__header,
    &__time {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 120px;
    }
    &__time {
        font-size: 12px;
        font-weight: 700;
        font-family: $fontSansSecondary;
        line-height: 18px;
        min-height: 33px;
        a,
        span {
            background: #F2F2F2;
            border: 1px solid #B3B3B3;
            border-radius: 8px;
            color: #0072ce;
            min-height: 31px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }
        &.online {
            a:before {
                display: inline-block;
                width: 13px;
                content: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.7618 3.65736V1.64487C9.7618 1.14664 9.22709 0.746094 8.55871 0.746094H1.37105C0.712956 0.746094 0.167969 1.14664 0.167969 1.64487V7.04732C0.167969 7.54555 0.712956 7.94609 1.37105 7.94609H8.569C9.22709 7.94609 9.77208 7.54555 9.77208 7.04732V5.03483L12.168 6.82262V1.86957L9.77208 3.65736H9.7618ZM8.55871 7.03755H1.37105V1.64487H8.569V7.04732L8.55871 7.03755ZM3.76694 3.44243C3.76694 2.94419 4.30164 2.54365 4.97003 2.54365C5.63841 2.54365 6.17311 2.94419 6.17311 3.44243C6.17311 3.94067 5.63841 4.34121 4.97003 4.34121C4.30164 4.34121 3.76694 3.94067 3.76694 3.44243ZM7.35563 6.13877H2.56386V5.88476C2.56386 5.70892 2.63584 5.53307 2.75923 5.38653C2.8929 5.23999 3.07799 5.12276 3.29393 5.05437C3.81835 4.87852 4.38391 4.7906 4.95974 4.7906C5.53558 4.7906 6.10113 4.87852 6.62555 5.05437C7.06771 5.20091 7.35563 5.5233 7.35563 5.88476V6.13877Z' fill='%23666666'/%3E%3C/svg%3E");
                margin-right: 5px;
            }
        }
        &.deactive,
        &.deactive a,
        &.deactive span {
            border: none;
            color: $gray-dark;
            cursor: default;
        }
    } 
}

.providers-list-result {
    display: flex;
    flex-direction: column;
    gap: 44px;
}

.call-to-schedule {
    min-height: 309px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        &:hover {
            color: #FFF;
        }
        svg {
            margin-right: .5rem;
        }
    }

    p {
        font-size: 16px;
        margin-bottom: 17px;
    }
}

.map-view .c-provider-card {
    h3 {
        font-size: 24px;
    }

    &__provider-details {
        background: none;
        flex-direction:column;
    }

    &__img {
        height: 65px;
        width: 74px;
    }

    &__appointments {
        flex-direction: column;
        padding: 0 1rem 1.5rem;
        gap: 1rem;
    }

    .c-provider-card__img-no-image {
        svg {
            max-width: 40px;
        }
    }

    .c-provider-card__provider-features {
        height: auto;
    }

    .c-location-card {
        padding: .5rem 1rem;
        border-right: 0;
        border-bottom: 1px solid #BA9CC5;
    }

    .call-to-schedule {
        min-height: auto;
        padding: .5rem 0 0;
    }
}

.provider-list-callout {
    border-radius: 0px 30px 0px 30px;
    background: #0072CE;
    color: #fff;
    padding: 1.25rem 2.25rem;
    margin-top: 44px;
    font-family: $fontSansSecondary;

    a {
        display: inline-flex;
        align-items: center;
        color: #fff;
        font-weight: 700;

        svg {
            margin-left: .5rem;
        }
    }

    span {
        display: block;
        font-size: 14px;

        &:first-child {
            font-size: 20px;
            font-weight: 700;

            @media screen and (min-width: 64em) {
                font-size: 26px;
            }
        }
    }
}

.map-view .provider-list-callout {
    margin-top: 1rem;

    span:first-child {
        font-size: 20px;
    }
}