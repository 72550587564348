.fad-filters {
  .fad-filters__close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% + 30px);
    background: #0072CE;
    color: #FFF;
    border-radius: 0;
    text-align: right;
    padding: 15px;
    margin: 0px -15px 2rem;
    font-family: $fontSansSecondary;

    svg {
      margin-left: 10px;
    }

    @media (min-width: $break) {
      display: none;
    }
  }
  .fad-filters__sticky-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    border-radius: 0;
    text-align: right;
    padding: 15px;
    gap: 1rem;

    .fad-filters__update {
      background: linear-gradient(90deg, #0072CE 3.45%, #009DDB 93.97%);
      color: $white;
      cursor: pointer;
      font-family: $fontSansSecondary;
      font-size: 15px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0.015em;
      text-align: center;
      padding: 13px 40px 13px 40px;
      border-radius: 50px;
      border: none;
      min-width: 138px;
      min-height: 40px;
    }

    .filter-clear {
      margin-bottom: 0;
      font-family: $fontSansSecondary;
    }

    @media (min-width: $break) {
      display: none;
    }
  }
  .fad-filters__header {
    @media (max-width: $break) {
      display: none;
    }
  }
  .fad-filters__form {
    color: $black;
    font-family: $fontSansSecondary;
    padding: 0;
    background: none;

    &.is-loading {
      /* TEMPORARY - loading indicator on form */
      cursor: wait;
      opacity: .75;

      button, input, a, select, .select2, .twitter-typeahead, .field-set {
        cursor: wait;
        pointer-events: none;
      }
      /* TEMPORARY - loading indicator on form */
    }
    
    @media screen and (min-width: 1024px) {
      border-radius: 0;
      padding: 0;
    }

    .field-set {
      margin-bottom: 30px;
      position: relative;

      .field-set {
        margin-bottom: 0;
      }
      
      .field-clear {
        position: absolute;
        right: 1px;
        background: #FFF;
        bottom: 1px;
        height: calc(2.4375rem - 3px);
        width: 40px;
        text-align: center;

        svg {
          pointer-events: none;
        }
      }
    }

    .filter-tooltip-container {
      position: relative;
    }

    .filter-tooltip {
      position: absolute;
      top: calc(100% + 10px);
      padding: 18px 30px;
      border-radius: 65px;
      background: #470A68;
      color: #FFF;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      z-index: 10;
      min-width: 330px;

      &:after {
        content: "";
        position: absolute;
        top: -8px;
        right: 30px;
        background: url('data:image/svg+xml;utf8,<svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.2998 7.34694C10.5527 5 8.55377 0 8.55377 0C8.55377 0 6.55273 5 0.807729 7.34694C-4.93728 9.69388 22.0469 9.69388 16.2998 7.34694Z" fill="#470A68"/></svg>');
      }
    }

    .twitter-typeahead {
      margin-top: 6px;
      width: 100%;
    }

    .filters__input--location {
      appearance: none;
      border: none;
      box-shadow: none;
      padding: 13px 20px;
      margin-bottom: 0;
    }

    label {
      color: $black;
      font-family: $fontSansSecondary;
      cursor: pointer;

      &.label-sm {
        font-size: 14px;
        line-height: 26px;
      }

      &.margin-bottom-md {
        margin-bottom: 14px;
      }

      @media screen and (min-width: 1024px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 8px;
        display: block;
      }

      & > span {
        position: relative;
        display: flex;
        align-items: center;

        &:after {
          content: "\f077";
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
          margin-left: 10px;
        }

        + input {
          margin-top: 10px
        }
      }

      &.closed {
        > span:after {
          content: "\f078";
        }
      }
    }

    .filters__title {
      font-size: 17px;
      font-weight: 17px;
      font-weight: 600;
      color: #75E8FF;
      display: none;
      font-family: $ringside;
      letter-spacing: -.5px;
      margin-bottom: 35px;
      text-transform: uppercase;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        display: block;
      }
    }

    .custom-select {
      .select2-container {
        margin-bottom: 38px;
      }
    }

    .select2-selection--multiple {
      input[type='search'] {
        font-size: 15px;
        line-height: 15px;
      }
    }

    .filters__geolocation-button {
      font-family: $fontSansSecondary;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      align-items: center;
      color: #fff;
      display: -ms-flexbox;
      display: flex;
      margin-top: .5rem;
      margin-bottom: 0;
      text-decoration: underline;
      color: #2472CC;
      border: none;

      svg {
        fill: none;
        margin-right: 5px;
        stroke: $white;
      }
    }

    .filters__select {

      @media screen and (min-width: 1024px) {
        margin-bottom: 37px;
      }
    }

    .filters__submit {
      @extend %button;
      background: white;
      color: $black;
      display: block;
      margin: 58px auto 0;
      padding: 20px 27px;
      position: relative;

      @media screen and (min-width: 1024px) {
        padding: 22px 30px;
        font-size: 17px;
        line-height: 17px;
      }

      &:focus,
      &:hover {
        color: $black;
        text-decoration: underline;
        &:before {
          display: none;
        }
      }
    }

    .form__input {
      border: 1px solid #666;
      font-family: $fontSansSecondary;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      padding: 7px 16px;
      position: relative;
      width: 100%;

      &[aria-expanded='false'] {
        background-image: url('/assets/images/icons/icon-search-blue.svg');
        background-position: 20px center;
        background-repeat: no-repeat;
        background-size: 22px 22px;
        padding: 13px 20px 13px 45px;
      }
    }

    .select2-results {
      text-align: center;
    }

    .tt-menu {
      background: #FFF;
      box-shadow: 0 11px 10px -6px rgba(0, 0, 0, 0.5);
      padding: 11px 20px;
      width: 100%;

      @media screen and (min-width: 1024px) {
        top: calc(100% - 10px) !important;
      }

      .tt-suggestion__item {
        font-size: 14px;
        font-weight: 400;
        line-height: 27px;
        display: block;
        letter-spacing: -.47px;
        text-align: left;

        @media screen and (min-width: 1024px) {
          font-size: 15px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: -1px;
        }
      }
      &#name-filter_listbox {
        max-height: 310px;
        overflow-y: auto;
      }
    }

    .select2-results__options[aria-multiselectable='true'] {
      text-align: left;

      .select2-results__option {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.17);
        color: #313131;
        font-family: $ringside;
        letter-spacing: -.5px;
        padding-left: 25px;
        padding-right: 5px;
        position: relative;

        &:before {
          background-color: rgba(187, 188, 188, 0.3);
          border-radius: 2px;
          border: 1px solid $blue;
          content: "";
          display: inline-block;
          height: 17px;
          left: 0;
          position: absolute;
          vertical-align: middle;
          width: 17px;
        }

        &[aria-selected='true'] {
          &:before {
            background-color: rgba(187, 188, 188, 0.3);
            background-image: url(/assets/images/icons/icon-check.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 70%;
            border: 1px solid $blue;
            color: #fff;
            content: '';
            display: inline-block;
            padding-left: 3px;
          }
        }

        &[aria-disabled='true'] {
          cursor: not-allowed;
          opacity: .65;
        }
      }

      + .select-submit {
        @extend %button;
        min-height: 0;
        margin: 20px auto 27px;

        @media screen and (min-width: 1024px) {
          font-size: 14px;
          padding: 14px 29px;
        }
      }
    }

    .select2-selection--multiple {
      .select2-selection__choice {
        display: none;
      }
    }

    .custom-select {
      position: relative;

      .select-status {
        bottom: 10px;
        color: $black;
        left: 10px;
        pointer-events: none;
        position: absolute;
        z-index: 20;
      }
    }

    .pac-container {
      font-family: $ringside;
      border: none;
      box-shadow: 0 0 4px rgba($black, .5);
      margin-top: 8px;
      z-index: 9999;

      &:after {
        background-image: none !important;
        height: 0;
        margin: 0;
        padding: 0;
      }

      .pac-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
        border-top: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.17);
        color: #313131;

        .pac-icon {
          background-image: url(/assets/images/icons/icon-map-pin.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    .checkbox__option {
      align-items: center;
      display: flex;
      margin-bottom: 27px;
      min-height: 22px;
      position: relative;
      z-index: 5;

      @media screen and (min-width: 1024px) {
        margin-bottom: 30px;
      }

      input {
        height: 22px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 22px;

        &:checked {
          + label {
            &:before {
              background: white;
            }
            &:after {
              opacity: 1;
            }
          }
        }
      }

      label {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -.5px;
        margin-bottom: 0;
        padding-left: 25px;

        &:after {
          color: $teal;
          content: "";
          border: 2px solid;
          border-left: 0;
          border-top: 0;
          height: 11px;
          left: 7px;
          opacity: 0;
          position: absolute;
          top: 4px;
          transform: rotate(45deg);
          transition: opacity 0.2s ease-in-out;
          width: 7px;
        }

        &:before {
          background: $white;
          border-radius: 2px;
          content: "";
          height: 22px;
          left: 0;
          position: absolute;
          top: 0;
          width: 22px;
        }
      }

      &.checkbox__option--50 {
        float: left;
        width: 50%;
      }
    }

    .filters-expand__button {
      &.button--more {
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        color: $white;
        display: flex;
        margin: auto;
        position: relative;
        text-align: center;

        &::after {
          bottom: 0;
          content: '';
          display: block;
          height: 20px;
          left: 0;
          position: absolute;
          width: 100%;
        }

        svg {
          margin: 0 auto;
          stroke: $white;
          stroke-width:2;
        }

        @media screen and (max-width: 1024px) {
          display: none;
        }

        &[aria-expanded="true"] {
          display: none;
        }
      }
    }

    .filters-expand__content {
      display: none;

      &[aria-hidden="false"] {
        display: block;
      }

      &[aria-hidden="true"] {
        display: none;
      }

      @media screen and (max-width: 1024px) {
        display: block;
        &[aria-hidden="false"] {
          display: block;
        }

      &[aria-hidden="true"] {
          display: block;
        }
      }
    }

    .input-circle,
    .input-square {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 13px;

      label, input {
        margin: 0;
      }

      input {
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: '';
          display: block;
          width: 18px;
          background: #fff;
          height: 18px;
          cursor: pointer;
          border: 1px solid #666;
        }

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          position: absolute;
        }

        &:checked {
          &::after {
            background: #2D8EFF;
          }
        }
      }
    }

    .input-square {
      input[type=checkbox] {
        &:checked {
          &::before {
            background-color: #2D8EFF;
          }
          &::after {
            border-width: 0 1px 1px 0;
            border-style: solid;
            border-color: #fff;
            transform: rotate(45deg);
            height: 8px;
            width: 4px;
            margin-top: -2px;
          }
        }
      }
    }

    .input-circle {
      input {
        &::before,
        &::after {
          border-radius: 50%;
        }
      }
    }

    .input-checkbox {
      display: flex;
      align-items: center;
      gap: 8px;

      input, label {
        margin: 0;
      }

      &.on-off {
        input {
          cursor: pointer;
          pointer-events: inherit; //override client css
          height: 22px;
          position: relative;
          width: 50px;
  
          &::before {
            content: "";
            background-color: #fff;
            border: 1px solid #666666;
            border-radius: 16px;
            display: flex;
            height: 20px;
            width: 48px;
          }
  
          &::after {
            content: 'OFF';
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #666666;
            color: #fff;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            line-height: 26px;
            font-weight: 700;
            top: -2px;
            // left: 0;
            // right: unset;
            transition: all $transition-duration ease-in;
            transform: translate(0, 0);
          }
  
          &:checked {
            &::after {
              content: 'ON';
              background: #0072CE;
              transform: translate(100%, 0);
            }
          }
        }        
      }

    }

    .fields-group {
      margin: 0 0 30px;
    }

    .lnk-button {
      font-family: $fontSansSecondary;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      color: #2472CC;
      border: none;
      padding: 0;
      text-decoration: underline;
      &.show-more {
        i {
          margin-left: 5px;
          transform: rotate(180deg);
        }
      }
    }
  }

  .content-col--sidebar-wide & {
    @media screen and (max-width: 768px) {
      max-width: 400px;
      opacity: 0;
      position: absolute;
      top: 65px;
      transform: translateY(0);
      transition: opacity .4s;
      width: 100%;

      &.is-open {
        opacity: 1;
        z-index: 7000;
      }

      + .mobile__options-wrapper {
        display: flex;

        .sort__mobile-select {
          font-size: 17px;
          font-weight: 600;
          line-height: 17px;
          background-image: url('/assets/images/icons/icon-sort.svg');
          background-position: -20px center;
          background-size: 20px;
          border: none;
          color: #32539E;
          height: 100%;
          // margin-left: 50px;
          min-height: 70px;
          // overflow: hidden;
          padding: 27px 27px 27px 65px;
          position: relative;
          text-overflow: ellipsis;
          z-index: 24;
          
          @media screen and (max-width: 768px) {
            min-width: 0;
            overflow: hidden;
          }

          &:focus,
          &:active {
            box-shadow: none;
          }

          .placeholder {
            text-transform: uppercase;
          }
        }

        .filters__mobile-toggle {
          font-size: 17px;
          font-weight: 17px;
          line-height: 17px;
          -webkit-tap-highlight-color: transparent;
          background: $blue;
          border-radius: 30px 0;
          color: #75E8FF;
          flex-direction: row;
          font-family: $ringside;
          margin-bottom: 24px;
          padding: 27px;
          position: relative;
          text-transform: uppercase;
          transition: all $transition-duration ease-in;
          width: auto;
          will-change: width;
          z-index: 24;

          &.is-open {
            border-radius: 30px 0 0 0;
          }

          .hidden-on-close {
            display: none;
          }

          svg {
            &:first-of-type {
              fill: $white;
              stroke: none;
            }
            fill: none;
            stroke: $white;
            stroke-width: 4;
          }

          .button__content {
            align-items: center;
            display: flex;
            position: relative;
            z-index: -1;
          }

          .button__text {
            letter-spacing: -.5px;
            margin: 0 13px;
            pointer-events: none;
            position: relative;
          }

          &.is-open {
            max-width: 400px;

            .hidden-on-close {
              display: inline;
            }

            svg {
              &:last-of-type {
                stroke: $white;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  .padding-left {
    padding-left: 10px;
  }
}
  
.button,
%button {
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(270deg, RGB(0, 157, 219) 0%, RGB(0, 114, 206) 100%);
  transition: all $transition-duration ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  min-height: 40px;
  padding: 14px 33px;
  vertical-align: top;
  color: $white;
  border-radius: 35px;
  transition: all .4s ease-in;
  cursor: pointer;
  font-family: $ringside;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    font-weight: 700;
    min-height: 62px;
  }

  &::after {
    // background-image: url(/assets/images/icons/icon-arrow-right-black.svg);
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 16px;
    position: absolute;
    right: 25px;
    transition: .4s ease;
    width: 25px;
    @include font(18, null, 700, 0);
    font-size: 18px;
    font-weight: 700;
    min-height: 62px;
  }

  &:before {
    @include gradientBlue(-270deg);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 500ms linear;
    z-index: -1;
  }

  .icon {
    margin-left: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:focus,
  &:hover {
    color: $white;

    &::after {
      transform: translateX(3px);
    }
    &:before {
      opacity: 1;
    }

    .icon {
      transform: translateX(5px);
    }
  }

  svg  {
    transition: all $transition-duration ease-in;
  }

  &:disabled {
    background: $white;
    border: 1px solid;
    border-color: #BBBCBC;
    color: #BBBCBC;
    cursor: not-allowed;

    svg {
      fill: #BBBCBC;
      left: 6px;
      position: relative;
      stroke: none;
    }
    
    @include hover {
      &:before {
        display: none;
      }
    }
  }
}