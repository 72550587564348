// optional
* {
    box-sizing: border-box;
}

#locations-app svg {
    stroke: inherit;
}

#locations-app {
    @media(min-width:992px){
        &.list {
            min-height: 1500px;

            .locations-content {
                padding-left: 440px;
            }
            .sidebar {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    &.map {
        overflow: hidden;
    }
}

.no-results {
    padding: 100px 60px;
    background: #F1F2F2;
    border-radius: 80px 0px 80px 0px;

    h4 {
        margin: 0;
        max-width: 90%;
    }

    p {
        font-weight: 700;
        font-size: 18px;
        font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
        margin-top: 1.5rem;
    }
    

    ul {
        font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
        margin-top: .5rem;
        list-style: initial;
        margin-left: 1rem;

        li {
            list-style: initial;
        }

        &.link-list {
            list-style: none;
            margin-left: 0;

            > li {
                margin-bottom: .75rem;
                font-weight: 700;
                list-style: none;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(255, 255, 255, .85);
    z-index: 100;
    backdrop-filter: blur(4px);

    .loading-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        filter: blur(0);
    }

    span {
        display: block;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #0072CE;
        font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
        margin-top: 1rem;
    }
}

.spinner {
    width: 90px; 
    height: 90px; 
    padding: 15px;
    background: linear-gradient(90deg, #0072CE 3.45%, #009DDB 93.97%);
    border-radius: 100%;
    position: relative;
    animation: spin 1s linear infinite;

    &:after { 
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        height: 60px;
        width: 60px;
        transform:translate(-50%, -50%);
        background: rgba(255,255,255, .8);
        z-index:10;
        border-radius: 100%;
    }

    &--sm {
        width: 40px; 
        height: 40px; 

        &:after {
            height: 27px;
            width: 27px;
        }
    }
}

.spinner-container {
    width: 90px;
    height: 150px;

    > svg {
        width: 90px; 
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.btn-primary {
    background: linear-gradient(270deg,#009ddb 0,#0072ce 100%);
    border-radius: 50px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.225px;
    padding: 0 40px;
    width: fit-content;
    border: 0;
    position: relative;

    &::before {
        background: linear-gradient(-270deg,#009ddb 0,#0072ce 100%);
        transition: all .4s ease-in;
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .5s linear;
        z-index: -1;
    }

    &:hover {
        &::before{
            background: linear-gradient(-270deg,#009ddb 0,#0072ce 100%);
            transition: all .4s ease-in;
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: opacity .5s linear;
            z-index: -1;
        }
    }
}
.btn-secondary {
    border-radius: 50px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.225px;
    padding: 0 40px;
    width: fit-content;
    border: 0;
    position: relative;
    color: #2D8EFF;
    border: 1px solid #2D8EFF;

    &.has-arrow {
        svg {
            max-width: 14px;
            margin-left: 0.5rem;
        }
    }

    &:hover {
        background: #2D8EFF;
        color: #fff;

        svg path {
            fill: #fff;
        }
    }
}
.hide-mobile {
    @media screen and (max-width: 63.99875em) {
        display: none;
    }
}
.hide-desktop {
    @media screen and (min-width: 64em) {
        display: none;
    }   
}

// optional end