.get-care-modal{
  z-index: 999;
  position:relative;

  *{
    box-sizing: border-box;
  }

  &__backdrop{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
  }

  &__content{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 20px;
    width: 100%;
    max-width: 740px;
    max-height: 90vh;
    overflow-y: scroll;

    @media (max-width: $break-sm) {
            max-width: calc(100% - 40px);
        }

        label{
          display: block;
      color: $black;
      font-family: $main-font;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 10px;
        }

        input[type="text"]{
          border: 1px solid $border-color;
      border-radius: 4px;
      background-color: $white;
      width: auto;
      min-width: 88px;
      padding: 10px;
      color: $black;
      font-family: $main-font;
      font-size: 14px;
      line-height: 16px;
        }

        .my-location{
          display: block;
        text-transform: uppercase;
        color: $orange;
        font-family: $main-font;
        font-size: 12px;
        font-weight: 700;
        height: 46px;
        width: 100%;
        text-align: left;
        background: $white;
        border: none;
        padding: 0 15px 0 0;
        cursor: pointer;
        position: relative;
        z-index: 3;
        }

        .get-care-modal{
          &__form-group{
            margin-bottom: 40px;
        max-width: 50%;

        @media (max-width: $break-sm) {
                max-width: none;
            }

            .get-care-modal__checkcontainer{
              &:last-child{
                margin-bottom:  0;
              }
            }
          }

          &__checkcontainer{
            margin: 0 0 20px;
        position: relative;

        input{
          &[type="radio"]{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

          ~ .radiobtn{
            font-family: $main-font;
            font-size: 14px;
            line-height: 16px;
            padding: 10px;
            position: relative;
            display: block;

            &:after{
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              border: 1px solid #EC5828;
            }

            span{
              color: #EC5828;
              position: relative;
              z-index: 1;
            }
          }

          &:checked{
            ~ .radiobtn{
              color: $white;

              &:after{
                background-color: #EC5828;
              }

              span{
                color: $white;
              }
            }
          }
        }
          }
        }

        .get-care-modal__controls{
          text-align: right;

          .get-care-modal__cancel, input[type="submit"]{
            padding: 10px 20px;
            display: inline-block;
            font-family: $main-font;
            font-size: 14px;
            line-height: 16px;
            border: none;
          }

          .get-care-modal__cancel{
            background: none;
            color: #EC5828;

        &:hover{
              cursor: pointer;
            }
          }

          input[type="submit"]{
            background-color: $orange;
            color: $white;

            &:disabled{
              background-color: $orange-light;
            }
          }
        }
  }
}