.category-filter {
    h4 {
        display: block;
        padding: 0 20px;
        font-size: 16px;
        color: $black;
        font-family: $main-font;

        @media (max-width: $break) {
            padding: 0 15px;
            margin: 15px 0 0;
        }

        &.clear-filters {
            button {
                border: none;
                padding: 0;
                margin: 0;
                font-size: 14px;
                font-weight: normal;
                color: $black;
                float: right;
                text-decoration: underline;
                background: transparent;
                cursor: pointer;

                &:disabled {
                    color: $gray;
                }

                @media (max-width: $break) {
                    display: none;
                }
            }
        }
    }

    .filter-block {
        padding: 10px 0 15px;

        >h4 {
            margin: 0;

            >a {
                display: inline-block;
                transform: rotate(-180deg);
                transition: transform 0.2s;
                font-size: 24px;
                vertical-align: middle;
                color: $black;
                margin-right: 15px;
            }
        }

        >ul {
            display: block;
            width: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            overflow: hidden;
            transition: max-height 0.2s;

            >li {
                display: block;

                .checkbox {
                    font-family: $main-font;
                    color: $gray;
                    font-size: 14px;

                    label {
                        padding: 20px 20px;
                        display: block;

                        @media (max-width: $break) {
                            padding: 15px;
                        }

                        .dot {
                            display: inline-block;
                            border-radius: 50%;
                            width: 10px;
                            height: 10px;
                            vertical-align: middle;
                            position: relative;
                            margin-right: 10px;
                            top: -1px;
                        }

                        &:after {
                            content: "";
                            display: block;
                            float: right;
                            width: 18px;
                            height: 18px;
                            line-height: 18px;
                            border: solid 1px $border-color;
                            border-radius: 2px;
                            text-align: center;
                            cursor: pointer;
                        }

                        .icon {
                            display: inline-block;
                            display: inline-block;
                            vertical-align: middle;
                            position: relative;
                            margin: 0 3px 0 -5px;
                            top: 0;
                            width: 20px;
                            height: 20px;

                            img {
                                display: block;
                                height: auto;
                                width: 20px;
                            }
                        }

                    }

                    input[type="checkbox"] {
                        position: fixed;
                        left: -1000px;
                        opacity: 0;
                    }

                    input[type="checkbox"]:checked+label {
                        background: #f6f6f6;

                        &:after {
                            font-family: $icon-font;
                            font-weight: $bold-weight;
                            border-color: $gray-dark;
                            color: $green;
                            content: "\f00c";
                        }
                    }
                }

                &.hide{
                    display: none;
                }

                .more-trigger{
                    color: #D93E0F;
                    font-family: $main-font;
                    font-size: 16px;
                    line-height: 19px;

                    a{
                        margin-left: 20px;
                        display: flex;
                        align-items: center;
                        width: 100%;

                        &:before{
                            content: "\f078";
                            font-family: $icon-font;
                            font-weight: $bold-weight;
                            color: #D93E0F;
                            margin-right: 20px;
                        }

                        &:hover{
                            cursor: pointer;
                        }

                        &.expanded{
                            &:before{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        &.collapsed {
            height: auto;
            >h4 {
                >a {
                    transform: rotate(0);
                }
            }

            >ul {
                max-height: 0;
            }
        }

        &.categories {

            >h4 {
                display: none;
            }

            >ul {
                max-height: initial;
                overflow: visible;
            }
        }
    }
}

.filter-options{
    a{
        cursor: pointer;
    }
    .form-check{
        &.hide{
            display: none 
        }
    }
}

.category-filter--appt {
    h4 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 0;
        margin: 0;
    }

    .categories{
        > ul {
            display: flex;

            @media (max-width: 475px){
                flex-wrap: wrap;   
            }

            > li{
                @media (max-width: 475px){
                    flex: 0 0 50%;
                    margin-bottom: .5rem;
                }

                &:nth-child(even){

                    .checkbox{
                        @media (max-width: 475px){
                            margin-right: 0;  
                        }
                    }
                }
            }

            > li > div{
                @media (max-width: $break) {
                    height: 100%;
                }
            }

            > li .checkbox {
                margin-right: .5rem;

                @media (max-width: $break) {
                    height: 100%;
                }

                @media (max-width: 475px){
                    margin-bottom: 0.5rem; 
                }
            }

            > li .checkbox input + label {
                background: #FFF;
                color: #EC5828;
                border: 1px solid #EC5828;
                border-radius: 5px;
                font-size: 1rem;
                padding: .75rem .75rem;
                cursor: pointer;

                @media (max-width: $break) {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    font-size: 0.875rem;
                    padding: .25rem .75rem;
                }

                @media (max-width: 475px){
                    justify-content: center; 
                }

                &:after {
                    display: none;
                }
            }

            > li .checkbox input[type=checkbox]:checked + label {
                background: #B92333;
                color: #FFF;
                border-color:#B92333;

                &:after {
                    display: none;
                }
            }

            > li .checkbox input[type=checkbox][disabled] + label {
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }

    // Remove once API done
    .specialties,
    .reasons {
        display: none !important;
    }
}