.location-list {
    padding: 15px 30px;
    min-height: 750px;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: $break) {
      padding: 0 15px 15px;
      overflow: auto;
      max-height: initial;
      min-height: initial;
    }

    &--availability {
      .cards {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px 35px;

        @media (min-width: 990px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        .list-card {
          height: 100%;
        }
      }
    }
  }