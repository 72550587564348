.search-filter {
    display: block;
    padding: 1px;
    margin: 10px;
    border-radius: 5px;
    h4, h5{
        font-family: $main-font;
        font-size: 21px;
        line-height: 19px;
    }
    h4{
        margin-top: 15px;
        margin-bottom: 10px;
    }
    h5{
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
    input {
      display: block;
      font-family: $main-font;
      color: $gray;
      height: 58px;
      font-size: 14px;
      width: 100%;
      padding: 0 15px;
      border: none;
      border-radius: 5px;
      border: solid 1px $border-color;
    }
    .my-location {
      display: block;
      text-transform: uppercase;
      color: $orange;
      font-family: $main-font;
      font-size: 12px;
      font-weight: $bold-weight;
      height: 46px;
      width: 100%;
      text-align: left;
      background: $white;
      border: none;
      padding: 0 15px;
      cursor: pointer;
      position: relative;
      z-index: 3;
    }
    .result {
      display: block;
      height: 20px;
      margin-bottom: 10px;
      position: relative;
      padding: 0 30px;
      span {
        color: $gray;
        font-family: $main-font;
        font-size: 12px;
      }
      a {
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        text-align: center;
        top: -38px;
        right: 10px;
        text-decoration: none;
        font-size: 28px;
        color: $gray;
        font-weight: normal;
        z-index: 10;
        &:hover {
          color: $orange;
        }
      }
    }
  }