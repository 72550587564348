.distance-filter {
    display: block;
    padding: 0 20px 15px;

    @media (max-width: $break) {
        padding: 15px;
    }

    h4, h5 {
        font-family: $main-font;
        font-size: 16px;
        color: $black;
        font-weight: $bold-weight;
        margin: 0 0 15px;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        >button {
            position:relative;
            flex: 1 0;
            border-left: none;
            color: $black;
            margin-bottom: 15px;
            padding-left: 5px;
            outline: none !important;
            box-shadow: none !important;
            cursor: pointer;

            &:before,
            &:after {
                content: "";
                position: absolute;
            }

            &:before {
                width:18px;
                height: 18px;
                border-radius: 100%;
                border: 1px solid;
                background: #FFF;
                left: -20px;
            }

            &:after {
                display: none;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background: #2D8EFF;
                left: -16px;
                top: 4px;
            }

            &.active {
                &:after {
                    display: block;
                }
            }

            &:disabled,
            &[disabled] {
                color: #666666;
                cursor: not-allowed;

                &:before {
                    background: #cdcdcd;
                    border-color: #cdcdcd;
                }

                &:after {
                    display: none;
                }
            }
        }
    }
}

.show-travel {
    input {
        &:disabled,
        &[disabled] {
            opacity: .5;
            cursor: not-allowed !important;

            + label {
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }
}

.distance-travel {
    margin-top: 20px;
}

.filters__geolocation-denied {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    margin-top: 12px;
    background: rgba(71, 10, 104, 0.09);
    border: 1px solid #BA9CC5;
    padding: 1.25rem;
}