.c-keyboard-search-hero {
    background: #F2F2F2;
    border-radius: 0px 0px 40px 0px;
    margin-bottom: 30px;

    @media (max-width: 1440px) {
        display: block;
    }

    @media screen and (min-width: 80em) {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        width: calc(100% - 60px)
    }

    &.search-hero-homepage {
        background: none;
        margin: 0;

        .c-keyboard-search-hero--content {
            background: none;
            padding: 0;
            min-height: unset;

            input {
                height: 3.25rem;
                padding: 0.8125rem;
                padding-left: 2.125rem;
            }

            svg {
                stroke-width: 0;
                transform: translate(17px, 17px);
            }
        }

        label {
            color: #000;
            line-height: 1.625rem;
            font-weight: 700;
            font-size: 1rem;
            font-family: inherit;
        }
    }

    nav {
        display: flex;
        gap: 30px;
        max-width: 1142px;
        margin: 0 auto;

        @media (max-width: $break) {
            gap: 0;
        }

        a {
            color: #0072CE;
            cursor: pointer;
            min-width: 233px;
            min-height: 63px;
            padding: 22px 0px 22px;
            border-radius: 40px 40px 0px 0px;
            gap: 10px;
            font-family: $fontSansSecondary;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            text-transform: uppercase;
            &.active,
            &:hover {
                background: #470A68;
                color: white;
            }

            @media (max-width: $break) {
                min-width: auto;
                flex: 1;
                min-height: 33px;
                font-size: 14px;
            }
    
        }
    }

    &--content {
        background: #470A68;
        min-height: 248px;
        padding: 50px 20px;
        border-radius: 0px 0px 40px 0px;
        gap: 30px;
        width: 100%;

        &-wrapper {
            margin: 0 auto;
            max-width: 1142px;
        }
    }

    h1 {
        color: $white;
        font-family: $fontSansSecondary;
        font-size: 50px;
        font-weight: 700;
        line-height: 56px;
        margin-bottom: 30px;
        letter-spacing: 0em;
        text-align: left;

    }

    label {
        color: $white;
        font-family: $fontSansSecondary;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        span {
            margin-bottom: 7px;
            display: flex;
        }
    }

    input[type='search'] {
        border: 1px solid #666666;
        color: #333;
        flex: 1;
        font-family: $fontSansSecondary;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        padding: 5px 20px 5px 45px;
        text-align: left;
        margin: 0;
        min-height:40px;

        @media (max-width: $break) {
            flex: 1;
        }
    }

    .keyboard-search-hero--filter {
        cursor: pointer;
        font-family: $fontSansSecondary;
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.015em;
        text-align: center;
        padding: 13px 20px 13px 20px;
        border-radius: 50px;
        background: #FFF;
        color: #2D8EFF;
        border: 1px solid #2D8EFF;
        display: none;

        > span {
            margin-bottom: 0;
            margin-left: 5px;
        }

        @media (max-width: $break) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            width: 100%;
        }
    }

    .keyboard-search-hero--submit {
        background: linear-gradient(90deg, #0072CE 3.45%, #009DDB 93.97%);
        color: $white;
        cursor: pointer;
        font-family: $fontSansSecondary;
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.015em;
        text-align: center;
        padding: 13px 40px 13px 40px;
        border-radius: 50px;
        border: none;
        min-width: 138px;
        min-height: 40px;
    }

    .input-set {
        display: flex;
        flex-direction: row;
        gap: 43px;
        position: relative;

        .icn-search {
            position: absolute;
            transform: translate(17px, 12px);
        }

        @media (max-width: $break) {
            flex-wrap: wrap;
            gap: 15px;
        }
    }

    p {
        font-size: 14px;
        font-family: $fontSansSecondary;
        color: #FFFFFF;
        line-height: 19px;
        margin: 0 0 2px;

        &.c-banner__content-highlighted {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
        }
    }
    a {
        color: #fff;
    }
}  