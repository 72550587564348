// font mixin allows for neater css setting font size, weight, line height, and tracking, with one mixin
// font size, line height, font weight, tracking in 1/1000 em
@mixin font(
  $font-size: null,
  $line-height: null,
  $font-weight: null,
  $tracking: null
) {
  @if $font-size {
    font-size: rem-calc($font-size);
  }
  @if $line-height {
    line-height: rem-calc($line-height);
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  @if $tracking {
    letter-spacing: #{calc($tracking/1000)}em;
  }
}

@mixin staggered-transitions(
  $nth: null,
  $items: null,
  $initial: null,
  $step: null
) {
  @for $i from $nth through $items {
    &:nth-of-type(#{$i}) {
      transition-delay: #{$initial}ms;
    }
    $initial: $initial + $step;
  }
}

// mixin for most commonly used background attributes with overrides
@mixin default-bg($position: center, $size: contain, $repeat: no-repeat) {
  background-position: $position;
  background-repeat: $repeat;
  background-size: $size;
}

// mixin for allowing pseudo elements to display
%pseudo {
  content: '';
  display: block;
}

@mixin hover {
  [data-whatinput='initial'] &:hover,
  [data-whatinput='initial'] &:focus,
  [data-whatintent='mouse'] &:hover,
  [data-whatintent='keyboard'] &:focus {
    @content;
  }
}

%scrollbar-style {
  // sass-lint:disable no-vendor-prefixes
  // always show scrollbars on webkit browsers
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: rem-calc(6);
    width: rem-calc(6);
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: rgba($gray-dark, .5);
    border-radius: rem-calc(3);
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: rgba($gray-dark, 1);
    border-radius: rem-calc(3);
  }
}

%scrollbar-style-light {
  // sass-lint:disable no-vendor-prefixes
  // always show scrollbars on webkit browsers
  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: rem-calc(6);
    width: rem-calc(6);
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: rgba($white, .1);
    border-radius: rem-calc(3);
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: rgba($white, .5);
    border-radius: rem-calc(3);
  }
}

@mixin content-contain {
  @include xy-grid-container;
  width: 100%;

  @include breakpoint(tablet-l down) {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  @include breakpoint(laptop) {
    max-width: rem-calc(1302);
  }
}

@mixin content-contain-wide {
  @include xy-grid-container;
  width: 100%;

  @include breakpoint(tablet-l down) {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  @include breakpoint(laptop) {
    max-width: rem-calc(1360);
  }
}

@mixin content-contain-narrow {
  @include xy-grid-container;
  max-width: rem-calc(870);
  width: 100%;

  @include breakpoint(tablet-l down) {
    padding-left: rem-calc(16);
    padding-right: rem-calc(16);
  }
}

@mixin u-cf {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin hover-underline {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  text-decoration: none;
  transition: background-size $transition-duration-fast;

  @include hover {
    background-size: 100% 2px;
  }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  @if $opacity == 0 {
    visibility: hidden;
  } @else {
    visibility: visible;
  }
}

@mixin abs-cover {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@mixin pseudo {
  content: '';
  display: block;
}

%abs-cover {
  @include abs-cover;
}

%psuedo {
  @include pseudo;
}

%show-for-sr {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

@mixin uppercase {
  text-transform: uppercase;
}

%uppercase {
  @include uppercase;
}

@mixin external-link {
  &:after {
    content: '';
    display: inline-block;
    background: url('/assets/images/icons/icon-external-link.svg');
    height: rem-calc(12);
    width: rem-calc(12);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: 10px;
    top: -5px
  }
}

%externalLink {
  @include external-link;
}

@mixin centerY {
  top: 50%;
  transform: translateY(-50%);
}

@mixin centerX {
  left: 50%;
  transform: translateX(-50%);
}

%centerY {
  @include centerY;
}

// Close icon
%icon-close {
  display: block;
  &:before,
  &:after {
    background-color: $white;
    content: ' ';
    height: 18px;
    left: 7px;
    position: absolute;
    top: 2px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.icon-close {
  @extend %icon-close;
}


// Gradients

@mixin gradientGreen($direction: 270deg) {
  background: linear-gradient($direction, RGB(64, 168, 41) 0%, rgb(0, 129, 109) 100%);
  background-position: right center;
}

@mixin gradientBlackTrans($direction: -87deg) {
  background: linear-gradient($direction, rgb(0, 0, 0) 0%, rgba(0, 0, 0, .76) 24%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0) 100%;
  background-position: right center;
  background-size: 200%;
}

@mixin gradientBlue($direction: 270deg) {
  background: linear-gradient($direction, RGB(0, 157, 219) 0%, RGB(0, 114, 206) 100%);
  transition: all $transition-duration ease-in;
}

@mixin gradientGrey($direction: 90deg) {
  background: linear-gradient($direction, RGB(207, 206, 206) 0%, RGB(187, 188, 188) 100%);
}