
.map-card {
    background: $white;
    display: block;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px rgba($black, 0.1);
    position: relative;

    &.show-me{
        animation: show-me-anim 1.5s .5s forwards;
    }

    @keyframes show-me-anim {
        0%{
            background: $white;
        }
        20%{
            background: rgba($orange, .1);
        }
        80%{
            background: rgba($orange, .1);
        }
        100%{
            background: $white;
        }
    }
  
    .border {
      position: absolute;
      top: 0;
      width: 3px;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: 0.2s;
    }
  
    h4 {
      font-size: 18px;
      line-height: 1.2em;
      display: block;
      font-family: $main-font;
      margin: 0 0 10px;
      transition: 0.2s;
      a {
        text-decoration: none;
        color: $black;
      }
    }
    p {
      &.address {
        font-family: $main-font;
        font-size: 14px;
        color: $black;
        margin: 0 0 10px;
      }
    }
  
    &:hover,
    &.active {
      border-left-color: $orange;
      h4 {
        a {
          color: $orange;
        }
      }
      .border {
        opacity: 1;
      }
    }
  
    .category {
      display: block;
      margin: 0 0 10px;
      .icon,
      .dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        vertical-align: baseline;
        margin-right: 10px;
      }
      span {
        font-size: 12px;
        color: $gray;
        font-weight: normal;
        font-family: $main-font;
      }
    }
    .distance {
      display: block;
      margin: 0 0 10px;
      font-size: 14px;
      font-family: $main-font;
      color: $gray;
      span {
        color: $white;
        background: $gray;
        border-radius: 5px;
        padding: 0 5px;
      }
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      .col {
        flex: 0 0 100%;
        width: 100%;
        > a {
          display: inline-block;
          color: $orange;
          font-family: $main-font;
          font-size: 15px;
          text-decoration: none;
          font-weight: $bold-weight;
          margin: 5px 5px 5px 0;
          &:hover {
            text-decoration: underline;
          }
          &.booking {
            display: block;
            background: $orange;
            color: $white;
            font-size: 14px;
            font-weight: bold;
            border-radius: 5px;
            text-align: center;
            padding: 15px 0;
            margin-top: 10px;
            &:hover {
              text-decoration: none;
              background: lighten($orange, 10%);
            }
          }
        }
      }
    }
  }