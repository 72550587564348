.paginator {
    // @media (min-width: $break) {
    //   display: none;
    // }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 70px;
    margin: 50px 0 0;
    padding: 15px;

    .col {
        flex: 0 auto;
        margin: 0;
        float: none;

        .map-active & {
            width: 100%;
        }

        .controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .map-active &{
                justify-content: space-evenly;
            }

            >button {
                display: block;
                border: none;
                background: transparent;
                width: 35px;
                height: 30px;
                font-size: 16px;
                color: $black;
                text-align: center;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: $orange;
                }

                &:disabled:not(.page) {
                    opacity: 0.35;
                    cursor: default;

                    &:hover {
                        color: currentColor;
                    }
                }

                &.page {
                    font-family: $main-font;
                    font-weight: $bold-weight;
                    font-size: 16px;
                    width: auto;
                    padding: 0 7px;
                    margin: 0 15px;
                    color: #2F72C7;

                    &.active {
                        position: relative;
                        display: block;
                        border-radius: 100%;
                        line-height: 28px;
                        text-align: center;
                        color: #FFF;
                        font-size: 16px;
                        font-family: $main-font;
                        font-weight: $bold-weight;

                        > span {
                            position:relative;
                            z-index: 10;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: #2F72C7;
                            border: 1px solid #2F72C7;
                            width: 45px;
                            height: 45px;
                            border-radius: 100%;
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }
}