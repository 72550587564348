.sort-field {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;

  &__label {
    text-transform: uppercase;
    margin-right: .75rem;
    font-size: 14px;
    font-weight: 600;
  }

  &__field {
    width: max-content;
    margin-left: auto;
    appearance: none;
    padding: .75rem 1.75rem .75rem .5rem;
    border-radius: 4px;
    background: url('/assets/images/icons/dropdown-arrow.svg') calc(100% - .5rem) center #FFFFFF;
    background-repeat: no-repeat;
    background-size: .75rem;
    font-family: $fontSansSecondary;
    border: 1px solid #D5D5D5;
  }
}