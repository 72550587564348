.list-card {
    background: $white;
    position: relative;
    display: block;
    padding: 30px;
    margin-bottom: 10px;
    box-shadow: 0 5px 10px rgba($black, 0.1);
    @media (max-width: $break) {
      padding: 15px;
    }
  
    .border {
      position: absolute;
      top: 0;
      width: 5px;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: 0.2s;
    }
  
    h3 {
      font-size: 24px;
      line-height: 1.2em;
      display: block;
      font-family: $main-font;
      margin: 0 0 10px;
      transition: 0.2s;
      @media (max-width: $break) {
        font-size: 18px;
      }
  
      a {
        color: $black;
        text-decoration: none;
      }
    }
    p {
      &.address {
        font-family: $main-font;
        font-size: 16px;
        color: $black;
        margin: 0 0 10px;
        @media (max-width: $break) {
          font-size: 14px;
        }
      }
    }
  
    &:hover,
    &.active {
      border-left-color: $orange;
      h3 {
        a {
          color: $orange;
        }
      }
      .border {
        opacity: 1;
      }
    }
  
    .category {
      display: block;
      margin: 0 0 10px;
      .dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        vertical-align: baseline;
        margin-right: 10px;
      }
      span {
        font-size: 12px;
        color: $gray;
        font-weight: normal;
        font-family: $main-font;
      }
    }
    .distance {
      display: block;
      margin: 0 0 5px;
      font-size: 14px;
      font-family: $main-font;
      color: $gray;
      span {
        color: $white;
        background: $gray;
        border-radius: 5px;
        padding: 0 5px;
      }
    }
    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .col {
        flex: 0 auto;
        width: auto;
        &:last-child {
          text-align: right;
        }
        > a {
          display: inline-block;
          color: $orange;
          font-family: $main-font;
          font-size: 16px;
          text-decoration: none;
          font-weight: $bold-weight;
          margin: 0 10px 10px 0;
          &:hover {
            text-decoration: underline;
          }
          @media (max-width: $break) {
            font-size: 14px;
          }
          &.booking {
            background: $orange;
            color: $white;
            font-size: 14px;
            font-weight: bold;
            border-radius: 5px;
            text-align: center;
            padding: 20px 30px;
            @media (max-width: $break) {
              padding: 20px 20px;
            }
            &:hover {
              text-decoration: none;
              background: lighten($orange, 10%);
            }
          }
        }
      }
    }

    .wait-list-title {
      display: block;
      font-weight: 600;
      margin-top: 2rem;
    }

    .wait-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 8px;
      padding: 0;
      margin: .5rem 0 1rem;
      list-style: none;

      > li,
      > a {
        padding: 8px 20px;
        text-align: center;
        border: 1px solid #D93E0F;
        color: #D93E0F;
        border-radius: 4px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .wait-time {
      font-size: 16px;
      font-weight: 500;

      span {
        display: block;
        font-weight: 700;
        font-size: 36px;
      }
    }
  }

  .locations-app-availability {
    .list-card {
      display: flex;
      flex-direction: column;
      font-family: "Roboto", sans-serif;
      padding: 20px;
      margin-bottom: 0;
      border-radius: 10px;
      box-shadow: 0 5px 40px 0 rgba(0,0,0,0.05);

      @media (max-width: $break){
        padding: 10px 20px 20px;
      }

      &:hover {
        .border {
          opacity: 0;
        }
      }

      .distance {
        margin: 1rem 0 .5;
        color: #000000;
        font-weight: 600;
        font-size: 1rem;

        span {
          padding: 0;
          border-radius: 0;
          color: #000000;
          background: none;
        }
      }

      .address {
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
      }

      h3 a {
        text-decoration: underline;
      }

      > .directions {
        color: #EC5828;
        font-weight: normal;
        font-size: 14px;
        text-decoration: none;
        margin-top: .25rem;
        margin-bottom: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }

      .actions {
        margin-top: auto;
      }

      .actions .col {
        flex: 0 0 100%;

        &:last-child {
          text-align: left;
        }
      }

      .actions .col > a.booking {
        display: block;
        width: 100%;
        margin: 1rem 0 0;
        padding: 14px 20px;
        background: #EC5828;
        color: #FFFFFF;
        font-weight: 600;
      }

      .wait-list{
        li{
          @media (max-width: $break) {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .insurance-card {
    border-radius: 0px 80px;
    background: rgba(#2F72C7, .1);
    text-align: center;
    padding: 40px 80px;

    h2 {
      font-size: 36px;
      font-weight: 700;
    }

    &__wrap {
      text-align: center;
      max-width: 705px;
      margin: 0 auto;
    }

    &__count {
      font-family: $main-font;
      font-size: 24px;
      font-weight: 700;
      margin: 0.5rem 0;
    }

    .btn-primary {
      margin: 1rem auto 0;
    }
  }