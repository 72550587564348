.c-location-card {
    padding: 5px 0 47px;

    @media screen and (min-width: 64em) {
        flex: 0 1 45%;
        border-right: 1px solid #BA9CC5;
        padding: 5px 18px 47px 15px;
    }

    &-details {
        padding: 0 0 0 27px;
        position: relative;

        .item__distance,
        .item__time,
        .item__direction {
            font-family: $fontSansSecondary;
            font-size: 16px;
            line-height: 18px;
            margin: 0 0 7px;
        }
        .item__direction {
            @media screen and (min-width: 64em) {
                max-width: 160px;
                margin-bottom: 10px;
            }
        }
        .item__get-direction a,
        .item__phone a {
            color: #2F72C7;
            font-family: $fontSansSecondary;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            margin: 0 0 7px;
        }
        .item__distance {
            display: inline-block;
            margin-bottom: 7px;
            svg {
                position: absolute;
                left: 0;
            }
        }
        .item__get-direction {
            margin: 0 0 24px;
        }
        .item__phone {
            svg {
                position: absolute;
                left: 0;
            }
        }
    }

    .location-details {
        display: none;

        &.active {
            display: block;
        }
    }

    .locations-no-result {
        p {
            font-size: 24px;
        }
    }
}

.c-location-card--none {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}