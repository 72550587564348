.c-banner {
    background: #470A68;
    border: 1px solid #5F2671;
    border-radius: 11px;
    padding: .75rem 2rem 0.938rem;
    margin: 0 0 40px;

    @media (max-width: 1440px) {
        display: block;
    }

    p {
        font-size: 14px;
        font-family: $fontSansSecondary;
        color: #FFFFFF;
        line-height: 19px;
        margin: 0 0 2px;

        &.c-banner__content-highlighted {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
        }
    }
    a {
        color: #fff;
    }
}  