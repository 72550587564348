.map-container {
    max-height: 940px;
    position: relative;
    overflow: hidden;
}
.map-view {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    position: relative;

    .loading {
        z-index: 9;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $white;//rgba($black, .9);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto, sans-serif;

        h3 {
            margin: 0;
            text-align: center;
            font-weight: bold;
            color: $orange;// $white;
            // text-shadow: 0 2px 4px rgba($black, 2);
        }
    }

    .map {
        display: block;
        flex: 1 1 auto;
        width: auto;
        height: auto;
        border-top: 2px solid $border-color;
    }

    .cards {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        box-shadow: inset 0 5px 10px rgba($black, 0.07);
        flex: 0 0 auto;
        width: 425px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 10px;
        max-height: 850px;
        scroll-behavior: smooth;
    }

    .gm-style {
        position: relative;

        .gm-style-iw-c {
            max-width: 200px !important;
            border-radius: 4px;
            border: 1px solid #f78e1f;
            padding: 15px;
            width: 100%;

            p {
                margin-top: 0;
                margin-bottom: 10px;
            }

            .button {
                display: block;
                text-decoration: none;
                color: $orange;
                font-weight: bold;
                padding: 5px 0;
                text-align: center;
                font-size: 14px;

                &:before {
                    display: inline-block;
                    content: "\f105";
                    font-family: "Font Awesome 5 Free";
                    color: $white;
                    background: $orange;
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    line-height: 1.2em;
                    margin-right: 5px;
                    text-align: center;
                    vertical-align: initial;
                }

                // &-full {
                //     display: block;
                //     background: $orange;
                //     color: $white;
                //     padding: 5px 0;
                //     font-size: 12px;
                //     border-radius: 4px;
                //     text-align: center;
                //     text-decoration: none;
                //     font-weight: bold;
                // }

                // &-link {}
            }
        }

        .gm-style-iw-t:after {
            display: none;
        }
    }

    &.option-1 {
        .gm-style {
            .gm-style-iw-c {
                border: none;
                z-index: 9;
                overflow: visible;
                $tsize: 12;
                $tsize2: 13;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -#{$tsize2}px;
                    left: 50%;
                    margin-left: -#{$tsize2}px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: #{$tsize2}px #{$tsize2}px 0 #{$tsize2}px;
                    border-color: $white transparent transparent transparent;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -#{$tsize}px;
                    left: 50%;
                    margin-left: -#{$tsize}px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: #{$tsize}px #{$tsize}px 0 #{$tsize}px;
                    border-color: $orange transparent transparent transparent;
                }

                .button {
                    background: $orange;
                    color: $white;
                    border-radius: 5px;
                    font-size: 14px;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .gm-style .gm-style-iw-c{
        max-width: 325px !important;
        border-radius: 10px;
        box-shadow: 0 5px 40px 0 rgba(0,0,0,0.05);
        border: none;
        padding: 20px 20px 20px !important;
        overflow-y: auto;
        max-height: auto !important;

        @media (max-width: $break) {
            top: 30px;
        }

        .gm-ui-hover-effect{
            top: 15px !important;
            right: 15px !important;
            padding: 0 !important;
            width: 20px !important;
            height: 20px !important;
            opacity: 1;

            > span{
                background-color: #000;
                width: 20px !important;
                height: 20px !important;
                margin: 0 !important;
            }
        }

        p.gmap-info__title{
            color: #671E75;
            font-family: $fontSansSecondary;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.25;
            margin-bottom: 0.25rem;
        }

        p.gmap-info__address, .directions{
            font-size: 14px;
            font-family: $fontSansSecondary;
        }

        .gmap-info__provider {
            &:not(:first-child){
                border-top: 1px solid #925D92;
                margin-top: 1.5rem;
                padding-top: 1rem;
            }

            > li {
                display: flex;
                overflow: hidden;
                gap: 1rem;
                margin-bottom: .75rem;

                figure {
                    width: 54px;
                    height: 46px;
                    border-radius: 0px 15px;
                    overflow: hidden;

                    img, svg {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }

                a, button {
                    appearance: none;
                    font-size: 14px;
                    background: none;
                    border-radius: 0;
                    border: 0;
                    color: #0072CE;
                    text-decoration: underline;
                    margin-bottom: .25rem;
                    font-weight: 700;
                    font-family: $fontSansSecondary;
                }

                > span {
                    display: block;
                    font-size: 12px;
                    font-family: $fontSansSecondary;
                    flex: 1;
                }

                .item__specialties {
                    display: flex;
                    flex-wrap: wrap;

                    span:not(:first-child) {
                        margin-left:3px;
                    }
                }
            }
        }

        .directions{
            color: #EC5828;
            text-decoration: none;
            margin-top: 10px;
            margin-bottom: 40px;
            display: inline-block;

            &:hover{
                text-decoration: underline;
            }
        }

        .gmap-info__book{
            @media (min-width: $break) {
                display: none;
            }

            > span{
                font-size: 1rem;
                font-weight: $bold-weight;
                line-height: 1.375rem;
            }
        }

        .wait-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0 8px;
            padding: 0;
            margin: .5rem 0 1rem;
            list-style: none;

            > li {
                padding: 8px 20px;
                text-align: center;
                border: 1px solid #D93E0F;
                color: #D93E0F;
                border-radius: 4px;

                @media (max-width: $break) {
                    font-size: 0.75rem;
                }
            }
        }

        .booking, .booking-btn {
            background: $orange;
            color: $white;
            font-size: 14px;
            font-weight: bold;
            border-radius: 5px;
            text-align: center;
            padding: 20px 30px;
            width: 100%;
            margin-top: 1rem;
            text-decoration: none;
            display: block;
            
            @media (max-width: $break) {
                padding: 20px 20px;
            }
            
            &:hover {
                background: lighten($orange, 10%);
            }
        }

        .booking-btn{
            &:before{
                display: none;
            }
        }
    }

    .gm-style .gm-style-iw-d{
        padding: 0;
        overflow-y: auto !important;
        max-height: 340px !important;
        min-height: 340px !important;

        > div{
            font-family: $main-font;
        }
    }

    .schedule-calendar__header {
        display: block;
        text-align: left;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0;

        .schedule-calendar__weekday {
            text-transform: none;
        }
    }

    .schedule-calendar__container {
        gap: inherit;
        width: 100%;

        .schedule-calendar__day {
            width: 100%;
        }
    }

    .schedule-calendar__container .slick-slide:first-child .schedule-calendar__hours,
    .schedule-calendar__container .schedule-calendar__day:first-child .schedule-calendar__hours {
        display: flex;
        gap: .5rem;
        width: 100%;
        flex-direction: row;
    }

    .schedule-calendar__container .slick-slide:first-child .schedule-calendar__hours .schedule-calendar__time,
    .schedule-calendar__container .schedule-calendar__day:first-child .schedule-calendar__hours .schedule-calendar__time {
        min-width: auto;
        flex: 1;
        min-height: auto;
        
        > a {
            padding: .5rem .5rem;
        }
    }

    .schedule-calendar__container .slick-slide:not(:first-child),
    .schedule-calendar__container .schedule-calendar__day:not(:first-child) {
        display: none;
    }

    .paginator {
        margin: 0;
    }
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(#000, .75);
    z-index: 9999;
}

.modal-inner {
    max-width: 910px;
    width: 100%;
    margin: auto;
}

.modal-close {
    display: flex;
    align-items: center;
    gap: .5rem;
    appearance: none;
    color: #fff;
    font-family: $fontSansSecondary;
    font-weight: 400;
    margin-left: auto;
    margin-bottom: 1rem;
}

.modal-window {
    border-radius: 0px 80px;
    background: #fff;
    border: 1px solid #BA9CC5;

    .c-provider-card .item__new, .c-provider-card .item__virtual, .c-provider-card .item__idioms, .c-provider-card .item__ages {
        max-width: 50%;
    }
}

.modal-actions {
    display: flex;
    gap: 1rem;
    margin-left: auto;
    padding: 1rem 36px 36px;
    justify-content: flex-end;
}

.loading-locations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}