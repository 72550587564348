$main-background: #fafafb;
$orange:#d43900;
$orange-light: #F29071;
$black: #000;
$white: #fff;
$green: #30b35b;
$gray: #7e7974;
$gray-dark: darken($gray, 10%);
$gray-light: #F2F2F2;
$blue: #0072ce;
$blue-light: #EDF5FC;
$purple: #470A68;
$purple-light: #5F2671;
$teal :#009edc;
$border-color: #CCCCCC;
$break:992px;
$break-sm:768px;

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 1700px,

  // device based
  phone: 0,
  phablet: 480px,
  tablet-p: 768px,
  tablet-l: 1024px,
  laptop: 1280px,
  desktop: 1440px,
);

$transition-duration: .4s;