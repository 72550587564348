// Webfont import from Google Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.6.3/css/all.css');
@import url(https://fonts.googleapis.com/css?family=Zilla+Slab:300,400,500,600,700|Raleway:300,400,500,600,700,800,900|Open+Sans:400,500,600,700);

$main-font: 'Roboto', sans-serif;
$icon-font: 'Font Awesome 5 Free';
$bold-weight: 700;

$fontSans: 'Raleway', 'Arial', 'Helvetica', sans-serif;
$fontSansSecondary: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
$fontSerif: 'Zilla Slab', 'Times New Roman', Times, serif;
$ringside: "Ringside Regular SSm A", "Ringside Regular SSm B";

$sentinel: "Sentinel SSm A", "Sentinel SSm B";

h4 {
    font-family: $fontSansSecondary;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    margin-top: 0;
    text-align: left;
}