.typeahead-container {
    position: relative;
}

.typeahead {
    position: absolute;
    background: #fff;
    top: calc(100% + 1rem);
    width: 100%;
    max-width: 85%;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #c1c2c2;
    border-radius: 10px;
    list-style: none;
    z-index: 10;
    font-family: $fontSansSecondary;

    ul > li {
        &.highlighted {
            background: #f2f2f2;
        }

        em {
            font-style: normal;
            font-weight: 700;
        }

        button {
            display: block;
            width: 100%;
            white-space: normal;
            text-align: left;
            line-height: 1.5;
            padding: .5rem 1.5rem;

            &:hover {
                background: rgba(71, 10, 104, 0.09);
            }
        }
    }

    &--sidebar {
        max-width: 100%;
        max-height: 300px;
        top: calc(100% + 1rem);
    }
}

.typeahead .typeahead__title {
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
    margin: 1rem 1.5rem;

    &:not(.typeahead__title--first) {
        margin-top: 1.25rem;
        padding-top: 1.25rem;
        border-top: 1px solid #c1c2c2;
    }
}

.pac-container {
    font-family: $fontSansSecondary;
    border: 1px solid #c1c2c2;
    border-radius: 10px;
    box-shadow: none;
    padding: 1.5rem;
}

.c-keyboard-search-hero:not(.search-hero-homepage) .typeahead {
    top: calc(100% - 43px);
    max-width: none;

    @media(min-width: 990px) {
        top: calc(100% + 1rem);
        max-width: 85%;
    }
}