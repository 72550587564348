.navbar {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px;
    height: 66px;
    line-height: 66px;
    border-bottom: 1px solid $border-color;

    @media (max-width: $break) {
      padding: 0;
      background: $white;
      margin-bottom: 5px;
    }
  
    .counter {
      flex: 0 auto;
      width: 50%;
      @media (max-width: $break) {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
      }
      h5 {
        font-family: $main-font;
        font-weight: normal;
        font-size: 14px;
        margin: 0;
        > span {
          font-weight: $bold-weight;
        }
      }
      .filter_toggle {
        @media (min-width: $break) {
          display: none;
        }
        background: $white;
        font-size: 12px;
        font-weight: bold;
        padding: 0 15px;
        border-radius: 5px;
        border: solid 1px $border-color;
        text-decoration: none;
        color: $gray-dark;
        height: 30px;
        line-height: 30px;
        &:hover {
          color: $orange;
        }
      }
    }
    nav {
      display: flex;
      align-items: center;
      flex: 0 auto;
      width: 50%;
      gap: 11px;
      padding-bottom: 8px;

      @media (max-width: $break) {
        display: none;
      }

      a {
        font-family: $fontSansSecondary;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #AB7CAB;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        gap: 11px;
        transition: .5s ease;

        &:focus,
        &.active,
        &:hover {
          color: #470A68;
          .view-icon svg path {
            fill: #470A68;
          }
        }
        .view-icon svg path {
          fill: #AB7CAB;
          transition: .5s ease;
        }
      }
    }
  }

  .navbar--availability {
    background: #FFF;
    display: block;
    height: auto;
    line-height: inherit;
    padding: 20px 30px;

    @media (min-width: 600px) {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: $break) {
      display: flex;
      padding: 15px 20px;
      margin-bottom: 1rem;
      flex-direction: row-reverse;
    }

    @media (min-width: $break) {
      background: none;
    }

    nav,
    .counter {
      width: auto;
    }

    .navbar__group {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;

      .counter {
        margin-left: 1rem;

        @media (max-width: $break) {
          margin-left: 0;
          margin-bottom: 1rem;
          padding-left: 0;
        }
      }

      @media (min-width: $break) {
        align-items: center;
        flex-direction: row;
      }
    }

    nav{
      @media (max-width: $break) {
        display: flex;
        width: 100%;
      }

      a{
        @media (max-width: $break) {
          font-size: 1rem;
          min-width: 50%;
        }

        &:first-child{
          @media (max-width: $break) {
            padding-left: 0;
          }
        }
      }
    }

    .sort-field{
      @media (max-width: $break){
        margin-top: 20px;
      }

      .form__select{
        @media (max-width: $break){
          flex: 2;
          margin-left: 20px;
        }
      }
    }

    > .navbar__col{
      @media (max-width: $break){
        width: 100%;
      }
    }
  }

  #map {
    height: 100%;
  }