.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 30%;
  max-width: 440px;
  position: relative;
  transition: width .5s ease;

  @media (max-width: $break) {
    max-width: initial;
    width: 100%;
  }

  .filter-clear {
    font-weight: 700;
    color: #0072CE;
    text-decoration: underline;
    margin-bottom: 30px;
  }

  .group {
    display: block;
    height: 100%;
    padding: 15px 0 0;
    border-right: 2px solid $border-color;
    overflow-x: hidden;
    overflow-y: auto;
    .mobile-header,
    .mobile-footer {
      display: none;
    }
    @media (max-width: $break) {
      &.hidden {
        display: none;
      }
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      padding: 50px 0 0;
      overflow: auto;
      min-height: initial;
      max-height: initial;
      border: none;
      .mobile-header {
        display: block;
        background: $orange;
        height: 50px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        > a {
          position: absolute;
          text-decoration: none;
          color: $white;
          font-size: 30px;
          font-family: $main-font;
          top: 5px;
          left: 15px;
        }
        > h4 {
          font-weight: normal;
          font-family: $main-font;
          font-size: 16px;
          color: $white;
          text-align: center;
          margin: 0;
          margin-top: 15px;
        }
      }
      .body {
        display: block;
        height: calc(100% - 55px);
        overflow: auto;
      }
      .mobile-footer {
        display: flex;
        background: $border-color;
        padding: 5px 15px;
        height: 55px;
        flex-wrap: wrap;
        a {
          font-family: $main-font;
          display: block;
          text-decoration: none;
          text-align: center;
          background: red;
          border-radius: 5px;
          height: 45px;
          line-height: 45px;
          font-size: 14px;
          padding: 0;
          &:first-child {
            flex: 0 0 auto;
            width: 80px;
            background: $white;
            color: $black;
            border: solid 1px $gray;
          }
          &:last-child {
            float: right;
            color: $white;
            background: $orange;
            flex: 1;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.list {
  #filter-sidebar {
    // background-color: $gray-light;
    // border-radius: 80px 0px 80px 0px;

    @media (max-width: $break) {
      background-color: $gray-light;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      z-index: 100;
      overflow-y: auto;
      border-radius: 0;
      display: none;

      &.active {
        display: block;
      }

      .collapsible-section--content-wrapper {
        padding: 0 15px 90px;
      }
    }
  }
  .collapsible-section--tab-buttons {
    display: none;
  }
}

.sidebar--horizontal {
  font-family: "Roboto", sans-serif;
  width: 100%;
  max-width: none;
  flex: 0 0 100%;
  padding: 20px 15px;

  @media (max-width: $break) {
    padding: 15px;
  }

  .group {
    background: #F0EEEE;
    border-right: 0;
    padding: 0;
    min-height: auto;
    
    @media (max-width: $break) {
      position: inherit;

      .body {
          height: auto;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: row;
      flex: 0 0 50%;

      &:first-child {
        flex-direction: row-reverse;

        @media (max-width: $break) {
          flex-direction: row;
          flex-wrap: wrap;
        }

        > .form-group{
          @media (max-width: $break) {
            flex: 0 0 100%;
            margin-bottom: 1rem;
          }

          &.search-filter{
            @media (max-width: $break) {
              margin-bottom: 0;
              flex: 2;
              padding-right: 20px;
            }
          }

          &:last-child{
            @media (max-width: $break) {
              flex: 1;
              display: block;
            }
          }
        }
      }

      &:last-child {
        border-top: 1px solid #736E69;
        margin-top: 1rem;
        padding-top: 1rem;
      }

      @media(min-width: $break) {
        &:first-child {
          flex-direction: row;
          padding-right: 1.5rem;
        }

        &:last-child {
          border-left: 1px solid #736E69;
          border-top: 0;
          padding-left: 2.5rem;
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }

  .search-filter {
    padding:0;
    margin: 0;

    @media (max-width: $break) {
      min-width: 143px;
    }

    .my-location {
      background: none;
      padding: 0;
      margin-top: 0.5rem;
      height: auto;

      @media (max-width: $break) {
        margin-top: 1rem;
      }

      &[disabled]{
        color: -internal-light-dark(graytext, rgb(170, 170, 170));
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  .form-group {
    flex: 1 1 auto;
    padding-right: 1rem;

    @media (max-width: $break){
      padding-right: 0;
    }

    &--2x {
      flex: 2 1 auto;
    }

    &--xs {
      flex: 0 1 auto;
      min-width: 105px;

      @media (max-width: $break) {
        display: none;
      }
    }
  }

  .form-label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;

    @media (max-width: $break) {
      margin-bottom: .25rem;
    }
  }

  .form-control {
    display: block;
    height: auto;
    width: 100%;
    padding: .75rem 0.5rem;
    appearance: none;
    background: #FFF;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    font-size: 1rem;

    @media (max-width: $break){
      font-size: 0.875rem;
    }

    &[disabled] {
      color: -internal-light-dark(graytext, rgb(170, 170, 170));
      opacity: 0.7;
    }
  }

  .form__select {
    background: url('/assets/images/icons/dropdown-arrow.svg') calc(100% - .5rem) center #FFFFFF;
    background-repeat: no-repeat;
    background-size: .75rem;
    padding-right: 1.5rem;
  }
}


// COMPONENT: COLLAPSIBLE CONTENT
.collapsible-section {

  .tab-button {
    background: linear-gradient(90deg, #009DDB 0%, #0072CE 96.83%);
    border: none;
    border-radius: 20px 20px 0px 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: $fontSansSecondary;
    font-size: 18px;
    font-weight: 700;
    gap: 10px;
    line-height: 18px;
    padding: 18px 16px 18px 16px;

    &[aria-expanded="true"]{
      .fa-chevron-up {
        transform: rotate(180deg);
      }
      .tab-button--shrinked {
        display: none;
      }
      .tab-button--expanded {
        display: block;
      }
    }

    .fas {
      transition: transform .5s ease;
    }
    .tab-button--shrinked {
      display: block;
    }
    .tab-button--expanded {
      display: none;
    }
  }

  &--tab-buttons, 
  &--content-wrapper {
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      list-style: none;
  }

  &--tab-buttons {
      position: absolute;
      white-space: nowrap;
      top: 0;
      left: 100%;
      opacity: 1;
      text-align: right;
      -webkit-transform-origin: 100% 100%;
      -webkit-transform: rotate(-90deg);
      transform-origin: 0 0;
      transform: rotate(90deg) translate(272px, -54px);
      transition: all .5s ease;

      > li {
        display: inline-block;
        cursor: pointer;
    }
  }

  &--content {
    li {
      white-space: nowrap;
    }
  }

  // FILTER SIDEBAR CONTENT
  &#filter-sidebar {
    
  }

}

.list {
  .collapsible-section {
    
    &--tab-buttons {
      opacity: 0;
    }

    .collapsible-section--content-wrapper {
      // padding: 60px 50px;
      padding: 0 30px;

      .fad-filters__form {
        background: none;
      }

      h4 {
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 10px;
      }
    }
  }
}

.map {
  .collapsible-section {
    background-color: $blue-light;
    box-shadow: 3px 0px 5px rgba(0,0,0,.15);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;

    &--content {
      display: none;
      &-wrapper {
        padding: 24px 20px;
        width: 350px;
        max-height: 940px;
        overflow-y: auto;
      }
    }
  }
}