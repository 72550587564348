.category-cta {
    display: flex;

    .cta__link {
        background: #fff;
        color: #2D8EFF;
        border: 1px solid #2D8EFF;
        border-radius: 2.1875rem;
        font-size: 0.9375rem;
        line-height: 0.9375rem;
        font-weight: 700;
        padding: 0px 1.5rem;
        min-height: 2.5rem;
        margin: 0;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:after {
            display: none;
        }

        &:before {
            transition: none;
        }

        &:hover {
            background: #d9dce0;

            svg {
                path {
                    fill: #FFF;
                }
            }
        }

        .category-search-buttons__list .cta__item-wrap .cta__link:hover::before, .category-search-buttons__list .cta__item-wrap .generic-form input[type="submit"]:hover::before, .generic-form .category-search-buttons__list .cta__item-wrap input[type="submit"]:hover::before {
            background: #d9dce0;
        }

        .cta__link-text {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            padding-right: 0.5rem;
            font-family: $fontSansSecondary;

            &:after {
                display: none;
            }
        }
    }
}

.c-category-list {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 63.99875em) {
        padding: 25px 0 0;
        background: #fff;
        margin-bottom: 10px;
    }
    
    &__wrapper {
        gap: 0.8125rem 0.625rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;

        @media (max-width: $break) {
            display: none;
        }

        .cta__item-wrap {
            max-width: none;
        }
        
        .category-cta .cta__item-wrap:first-child {
            padding-left: 0;
        }

        a.cta {
            @media screen and (max-width: 63.99875em) {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: 12px;
                font-size: 14px;
                line-height: 15px;
                letter-spacing: 0.21px;
            }
        }
    }

    &__results-label {
        font-family: $fontSansSecondary;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .cta {
        font-family: $fontSansSecondary;
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.015em;
        text-decoration-line: underline;
        color: #0072CE;
        margin-left: 20px;
    }
}

.list {
    .c-category-list {
        flex-direction: column;
        align-items: baseline;
        gap: 0;
    }
}

.map {
    .c-category-list__results-label {
        margin-bottom: 0;
    }
}
